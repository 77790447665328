import Title from './components/Title'
import UploadFile from './components/UploadFile' 

function App() {
  return (
    <main>
      <Title />
      <UploadFile />
    </main>
  )
}

export default App
