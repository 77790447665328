const Title = () => {
  return (
    <div className='title'>
      <h1>
        <span>CRM</span> upload template
      </h1>
    </div>
  );
};
export default Title;
